import React, { useState } from 'react';
import './signup.css'; // Import CSS file

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signupMessage, setSignupMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setShowModal(true); // Show the modal instead of setting the spinner state
        try {
            const response = await fetch('/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });
            const data = await response.json();
            if (data.success) {
                setSignupMessage(data.message);
            } else {
                setSignupMessage(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setSignupMessage('Error registering user.');
        } finally {
            setShowModal(false);
        }
    };    

    return (
        <div className="signup-body">
            <header className="signup-header">
                <div className="signup-banner">
                    <h1 className="logo">ThankQ</h1>
                    <nav className="signup-nav">
                        <ul>
                            <li><a href="home">Home</a></li>
                            <li id="demoNavItem" style={{ display: 'none' }}><a href="demo">Demo</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
            <main>
                <section id="signupSection">
                    <h2>Thanks for Signing Up!</h2>
                    <form id="signupForm" onSubmit={handleSubmit}>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        <label htmlFor="password">Password:</label>
                        <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        <button type="submit" className="signup-button">Sign Up</button>
                    </form>
                </section>
            </main>
            <footer>
                {/* Your footer content here */}
            </footer>
            {showModal && (
                <div id="signupStatusModal" className="popup">
                    <div className="popup-content">
                        <p id="signupStatusMessage">{signupMessage}</p>
                        <button id="okayButton" onClick={() => setShowModal(false)}>Okay</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Signup;
