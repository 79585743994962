import React, { useState } from 'react';
import './Chat.css'; // Import your ChatWidget CSS file

const ChatWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`chat-widget ${isOpen ? 'open' : ''}`}>
      <button className="toggle-button" onClick={toggleChat}>
        <div className="button-container">
          {isOpen ? 'Close Chat' : 'Open Chat'}
        </div>
      </button>
      <div className="chat-content">
        {/* Your chat interface goes here */}
        {/* Replace this with your actual chat interface */}
        <iframe
          src="https://app.chatsimple.ai/iframe23/38d6b488-13ce-4511-ad78-4f7c0d3ae933/8ad4e864-daf2-4a47-bcf4-3382a96435ed/3fa18fa4-aa65-4d09-ab4e-c7b41fa7e571"
          height="400"
          width="300"
          title="Chatsimple"
        ></iframe>
      </div>
    </div>
  );
};

export default ChatWidget;
