import React, { useState, useEffect } from 'react';
import './signup.css'; // Import CSS file
import ThankQLogo from './pictures/ThankQLogo.png'; // Import ThankQ Logo image

const Upload = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [fileInput, setFileInput] = useState(null);
    const [spinnerVisible, setSpinnerVisible] = useState(false);

    useEffect(() => {
        const userCookie = document.cookie
            .split('; ')
            .find(row => row.startsWith('user='));

        if (userCookie) {
            window.location.href = '/upload'; // Redirect to the upload section if user is logged in
        }
    }, []);

    const handleVideoUpload = async () => {
        const formData = new FormData();
        formData.append('file', fileInput.files[0]);

        setSpinnerVisible(true); // Show spinner overlay

        try {
            const response = await fetch('/upload', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.text();

            // Update modal content
            setModalContent(data + ' View your video in the Demo Tab :)');
            setShowModal(true);
        } catch (error) {
            console.error('Error uploading file:', error);
            setModalContent('Error uploading file. Please try again.');
            setShowModal(true);
        } finally {
            setSpinnerVisible(false); // Hide spinner overlay
        }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <header>
                <div className="banner">
                    <h1 className="logo">ThankQ</h1>
                    <nav>
                        <ul id="navList">
                            <li><a href="home">Home</a></li>
                            <li id="demoNavItem" style={{ display: 'none' }}><a href="demo">Demo</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
            <main>
                <section id="uploadSection">
                    <h2>Upload Your Video</h2>
                    <form encType="multipart/form-data">
                        <input type="file" name="file" id="videoInput" accept="video/*" onChange={(e) => setFileInput(e.target)} />
                        <button type="button" onClick={handleVideoUpload}>Upload File!</button>
                    </form>
                    <div id="uploadStatusModal" className="modal" style={{ display: showModal ? 'block' : 'none' }}>
                        <p id="modalContent">{modalContent}</p>
                        <button id="okayButton" onClick={closeModal} style={{ display: 'none' }}>Okay</button>
                    </div>
                </section>
            </main>
            <footer>
                {/* Your footer content here */}
            </footer>
            <div className="spinner-overlay" style={{ display: spinnerVisible ? 'flex' : 'none' }}>
                <img id="spinner" src="pictures/spinner.gif" alt="Loading..." className="spinner" />
            </div>
        </div>
    );
};

export default Upload;
