import React from "react";
import './calendly.css';

const CalendlyButton = ({ url }) => {
  return (
    <div className="floating-widget">
      <a className="widget-button" href={"https://calendly.com/jerome-lu/30min"} target="_blank" rel="noopener noreferrer">
        Click here to schedule a meeting!
      </a>
    </div>
  );
};

export default CalendlyButton;
