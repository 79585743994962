import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './style.css'
import NotFound1 from './views/not-found.js'
import Signup from './views/signup.js'
import Upload from './views/upload.js'
import Home from './views/home'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Signup />} path="/signup" />
        <Route element={<Upload />} path="/upload" />
        {/* <Route element={<NotFound1 />} path="*" /> */}
        {/* <Route element={<Navigate to="/not-found" />} /> */}
      </Routes>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
